import React from 'react';
import styled from "styled-components";
import CheckLogoSVG from "../../images/CheckLogoSVG";
import {Link} from "react-router-dom";
import HistorySVG from "../../images/HistorySVG";
import CalcSVG from "../../images/CalcSVG";
import UserSVG from "../../images/UserSVG";
import {uiScale} from "../../config";

const Links = styled.div`
    display: flex;
    align-items: center;
    font-weight: 500;
    gap: calc(48px * ${uiScale});
    margin-left: auto;

    a {
        display: flex;
        align-items: center;
        gap: calc(12px * ${uiScale});
        text-decoration: none;
        color: ${({theme}) => theme.gray2};

        &:hover {
            &:not(.profileLink) {
                color: ${({theme}) => theme.blue};

                svg path {
                    fill: ${({theme}) => theme.blue};
                }
            }
        }
    }

    .profileLink {
        background: ${({theme}) => theme.blue};
        padding: calc(12px * ${uiScale}) calc(14px * ${uiScale});
        color: ${({theme}) => theme.white0};
        border-radius: calc(999px * ${uiScale});
        gap: calc(10px * ${uiScale});
        display: flex;

        &:hover {
            background: ${({theme}) => theme.darkBlue};
        }
    }
`;

const StyledCheckHeader = styled.div`
    display: flex;
    align-items: center;
    padding: calc(10px * ${uiScale}) calc(10px * ${uiScale}) calc(10px * ${uiScale}) calc(20px * ${uiScale});
    margin: calc(20px * ${uiScale}) calc(100px * ${uiScale}) calc(60px * ${uiScale});
    box-shadow: 0 0 calc(8px * ${uiScale}) 0 #00000029;
    border-radius: calc(999px * ${uiScale});
`;

function CheckHeader() {
    return (
        <StyledCheckHeader>
            <CheckLogoSVG/>
            <Links>
                <Link to={"/"}>Поиск</Link>
                <Link to={"/history"}>История <HistorySVG/></Link>
                <Link to={"/tariffs"}>Тарифы<CalcSVG/></Link>
                <Link to={"/profile"} className={"profileLink"}>Профиль <UserSVG/></Link>
            </Links>
        </StyledCheckHeader>
    );
}

export default CheckHeader;
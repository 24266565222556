import styled, {css} from "styled-components";
import {Input} from "antd";
import {uiScale} from "../../config";

export const StyledInputBase = css`
    font-size: calc(20px * ${uiScale});
    border-color: ${({theme}) => theme.gray};
    padding: calc(15px * ${uiScale});
    line-height: calc(26px * ${uiScale});
`;
export const StyledInput = styled(Input)`
    ${StyledInputBase};
`;

const Password = styled(Input.Password)`
    ${StyledInputBase};
`;

StyledInput.Password = Password;
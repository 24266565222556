import styled, {css} from "styled-components";
import {Checkbox, Select} from "antd";
import {uiScale} from "../../config";

export const StyledCheckboxBase = css`
    font-size: calc(14px * ${uiScale});
    line-height: calc(18px * ${uiScale});
    color: ${({theme}) => theme.gray3};
    font-weight: 500;
`;
export const StyledCheckbox = styled(Checkbox)`
    ${StyledCheckboxBase};
`;

import React from 'react';
import styled from "styled-components";
import {Form, Space} from "antd";
import {StyledButton} from "../components/styled/StyledButton";
import CheckLogoSVG from "../images/CheckLogoSVG";
import {StyledCheckInput} from "../components/styled/StyledCheckInput";
import {useAppDispatch, useAppSelector} from "../redux/hooks";
import {Link, useNavigate} from "react-router-dom";
import {editUserAction} from "../redux/pageSlice";
import {api, uiScale} from "../config";

const FormWrap = styled.div`
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 calc(8px * ${uiScale}) #0000004D;
    padding: calc(50px * ${uiScale});
    border-radius: calc(24px * ${uiScale});
    max-width: calc(760px * ${uiScale});
    width: 100%;

    .ant-form {
        .ant-form-item {
            margin-bottom: 0;

            &:not(:last-child) {
                margin-bottom: calc(10px * ${uiScale});
            }
        }

        label {
            font-size: calc(18px * ${uiScale});
            font-weight: 500;
            color: ${({theme}) => theme.gray3};
        }
    }

    .top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: calc(36px * ${uiScale});

        h2 {
            font-size: calc(28px * ${uiScale});
            font-family: "Suisse Intl", Arial, sans-serif;
            margin-bottom: 0;
        }
    }
`;

const StyledProfilePage = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    flex-direction: column;
    padding-bottom: calc(100px * ${uiScale});

    .ant-space, .ant-space-item {
        width: 100%;
    }

    ${StyledButton} {
        padding: calc(12px * ${uiScale}) calc(18px * ${uiScale});
        font-size: calc(16px * ${uiScale});
        width: 100%;
        height: calc(54px * ${uiScale});
    }

    h1 {
        font-size: calc(46px * ${uiScale});
        font-family: "Suisse Intl", Arial, sans-serif;
        margin-bottom: calc(40px * ${uiScale});
    }
`;

function ProfilePage() {
    const user = useAppSelector((state) => state.page.user);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    return (
        <StyledProfilePage>
            <h1>Профиль</h1>
            <FormWrap>
                <span className="top"><h2>Редактирование</h2><CheckLogoSVG/></span>
                <Form onFinish={(fields) => {
                    dispatch(editUserAction(fields))
                }} layout={"vertical"} initialValues={user || {}}>
                    <Form.Item label={"Логин"} name={"Login"}>
                        <StyledCheckInput disabled/>
                    </Form.Item>
                    <Form.Item label={"Пароль"} name={"Password"}>
                        <StyledCheckInput/>
                    </Form.Item>
                    <Form.Item label={"Имя"} name={"Name"}>
                        <StyledCheckInput/>
                    </Form.Item>
                    <Form.Item label={"Номер телефона"} name={"Phone"}>
                        <StyledCheckInput/>
                    </Form.Item>
                    <Form.Item style={{marginBottom: 36}}>
                        <Space size={12}>
                            <Form.Item label={"Осталось запросов"} name={"TotalSearchLimit"}>
                                <StyledCheckInput disabled/>
                            </Form.Item>
                            <Form.Item label={"Тарифы"}>
                                <Link to={"/tariffs"}><StyledButton>Перейти в тарифы</StyledButton></Link>
                            </Form.Item>
                        </Space>
                    </Form.Item>
                    <Form.Item>
                        <Space size={20}>
                            <StyledButton className={"danger"} onClick={() => navigate(-1)}>Назад</StyledButton>
                            <StyledButton htmlType={"submit"} className={"success"}>сохранить</StyledButton>
                        </Space>
                    </Form.Item>
                    <Form.Item style={{marginTop: 36}}>
                        <Space size={20}>
                            <a href={`${api}/exit`}>
                                <StyledButton className={"black"}>Выйти</StyledButton>
                            </a>
                        </Space>
                    </Form.Item>
                </Form>
            </FormWrap>
        </StyledProfilePage>
    );
}

export default ProfilePage;
import React, {SVGProps} from 'react';

function HistorySVG(props: SVGProps<SVGSVGElement>) {
    return (
        <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M3 10H21V20.004C21 20.554 20.555 21 20.007 21H3.993C3.86238 20.9999 3.73307 20.974 3.61246 20.9239C3.49184 20.8737 3.3823 20.8003 3.29007 20.7078C3.19785 20.6153 3.12477 20.5055 3.07499 20.3848C3.02522 20.264 2.99974 20.1346 3 20.004V10ZM9 12V14H15V12H9ZM2 4C2 3.448 2.455 3 2.992 3H21.008C21.556 3 22 3.444 22 4V8H2V4Z"
                fill="#AFAFAF"/>
        </svg>

    );
}

export default HistorySVG;
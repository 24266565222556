import React from 'react';
import styled from "styled-components";

const StyledMainLayout = styled.div`
    display: flex;
    width: 100%;
`;

type LayoutProps = {
    children: React.ReactNode
}

function MainLayout({children}: LayoutProps) {
    return (
        <StyledMainLayout>
            {children}
        </StyledMainLayout>
    );
}

export default MainLayout;
import styled, {css} from "styled-components";
import {Input} from "antd";
import {uiScale} from "../../config";

export const StyledCheckInputBase = css`
    font-size: calc(20px * ${uiScale});
    border-color: ${({theme}) => theme.gray5};
    padding: calc(13px * ${uiScale});
    line-height: calc(26px * ${uiScale});
    border-radius: calc(14px * ${uiScale});
`;
export const StyledCheckInput = styled(Input)`
    ${StyledCheckInputBase};
`;

const Password = styled(Input.Password)`
    ${StyledCheckInputBase};
`;

StyledCheckInput.Password = Password;
import React, {SVGProps} from 'react';

function MailSVG(props: SVGProps<SVGSVGElement>) {
    return (
        <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M3 4H21C21.2652 4 21.5196 4.09365 21.7071 4.26035C21.8946 4.42705 22 4.65314 22 4.88889V19.1111C22 19.3469 21.8946 19.573 21.7071 19.7397C21.5196 19.9064 21.2652 20 21 20H3C2.73478 20 2.48043 19.9064 2.29289 19.7397C2.10536 19.573 2 19.3469 2 19.1111V4.88889C2 4.65314 2.10536 4.42705 2.29289 4.26035C2.48043 4.09365 2.73478 4 3 4ZM12.06 11.7182L5.648 6.87822L4.353 8.23289L12.073 14.0596L19.654 8.22844L18.346 6.88356L12.061 11.7182H12.06Z"
                fill="white"/>
        </svg>

    );
}

export default MailSVG;
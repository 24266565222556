import React, {SVGProps} from 'react';

function EyeSVG(props: SVGProps<SVGSVGElement>) {
    return (
        <svg {...props} width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_309_7591)">
                <path
                    d="M0.787109 8.49805C1.41378 5.08471 4.40511 2.49805 7.99978 2.49805C11.5944 2.49805 14.5851 5.08471 15.2124 8.49805C14.5858 11.9114 11.5944 14.498 7.99978 14.498C4.40511 14.498 1.41444 11.9114 0.787109 8.49805ZM7.99978 11.8314C8.88383 11.8314 9.73168 11.4802 10.3568 10.8551C10.9819 10.2299 11.3331 9.3821 11.3331 8.49805C11.3331 7.61399 10.9819 6.76615 10.3568 6.14102C9.73168 5.5159 8.88383 5.16471 7.99978 5.16471C7.11572 5.16471 6.26788 5.5159 5.64275 6.14102C5.01763 6.76615 4.66644 7.61399 4.66644 8.49805C4.66644 9.3821 5.01763 10.2299 5.64275 10.8551C6.26788 11.4802 7.11572 11.8314 7.99978 11.8314ZM7.99978 10.498C7.46934 10.498 6.96064 10.2873 6.58556 9.91226C6.21049 9.53719 5.99978 9.02848 5.99978 8.49805C5.99978 7.96761 6.21049 7.45891 6.58556 7.08383C6.96064 6.70876 7.46934 6.49805 7.99978 6.49805C8.53021 6.49805 9.03892 6.70876 9.41399 7.08383C9.78906 7.45891 9.99978 7.96761 9.99978 8.49805C9.99978 9.02848 9.78906 9.53719 9.41399 9.91226C9.03892 10.2873 8.53021 10.498 7.99978 10.498Z"
                    fill="white"/>
            </g>
            <defs>
                <clipPath id="clip0_309_7591">
                    <rect width="16" height="16" fill="white" transform="translate(0 0.498047)"/>
                </clipPath>
            </defs>
        </svg>

    );
}

export default EyeSVG;
import React, {SVGProps} from 'react';

function CheckLogoSVG(props: SVGProps<SVGSVGElement>) {
    return (
        <svg {...props} width="180" height="34" viewBox="0 0 180 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.72768 30.0464H0L11.4153 0.773071H17.2224L5.72768 30.0464Z" fill="#0F09FF"/>
            <path
                d="M20.3164 17.1641C20.3164 14.6061 21.1158 12.5005 22.7146 10.8475C24.3134 9.19468 26.3593 8.36816 28.8525 8.36792C31.3129 8.36792 33.3074 8.99934 34.8358 10.2622C36.3642 11.5251 37.2097 13.2458 37.3722 15.4243H32.2181C31.8495 13.7878 30.7926 12.9694 29.0476 12.9693C27.9635 12.9693 27.0991 13.3487 26.4542 14.1075C25.8092 14.8663 25.4868 15.8852 25.4868 17.1641C25.4868 18.4974 25.7958 19.5353 26.4136 20.2778C27.0314 21.0203 27.8877 21.3916 28.9825 21.3915C30.8142 21.3915 31.8927 20.5677 32.2181 18.9202H37.3722C37.2097 21.0232 36.3534 22.7277 34.8033 24.0337C33.2531 25.3399 31.3128 25.993 28.9825 25.9929C26.3485 25.9929 24.2457 25.188 22.674 23.5784C21.1022 21.9688 20.3163 19.8307 20.3164 17.1641Z"
                fill="black"/>
            <path
                d="M45.2915 25.5538H40.1699V0.449707H45.3241V10.6279C45.8647 9.88782 46.5989 9.31119 47.4459 8.9614C48.3883 8.55822 49.4045 8.35608 50.4294 8.36789C52.4131 8.36789 53.9523 8.97219 55.0471 10.1808C56.1418 11.3896 56.6892 13.0832 56.6892 15.2618V25.5538H51.5514V16.4974C51.5514 15.4462 51.2858 14.6061 50.7546 13.9773C50.495 13.6681 50.1681 13.4224 49.7989 13.259C49.4298 13.0956 49.0281 13.0187 48.6247 13.0343C47.6057 13.0343 46.7954 13.3432 46.194 13.961C45.5924 14.579 45.2916 15.4028 45.2915 16.4325V25.5538Z"
                fill="black"/>
            <path
                d="M68.0711 25.9929C65.5563 25.9929 63.4887 25.1663 61.8683 23.5133C60.2477 21.8605 59.4375 19.7549 59.4375 17.1967C59.4375 14.6061 60.2315 12.4869 61.8194 10.8393C63.4073 9.1918 65.4587 8.36802 67.9736 8.36792C70.5857 8.36792 72.6533 9.16464 74.1764 10.7581C75.6992 12.3514 76.4607 14.5085 76.4608 17.2292V18.53L64.3803 18.5949C64.521 19.6899 64.9058 20.5029 65.5347 21.0339C66.1632 21.565 67.0412 21.8306 68.1687 21.8306C69.1333 21.8306 69.9381 21.6625 70.5832 21.3265C70.8752 21.191 71.1363 20.997 71.3502 20.7565C71.5642 20.5159 71.7265 20.234 71.827 19.9282H76.5259C76.3929 20.8124 76.0805 21.6601 75.608 22.4192C75.1354 23.1783 74.5127 23.8328 73.7781 24.3425C72.2497 25.4428 70.3474 25.993 68.0711 25.9929ZM68.0061 12.5302C66.9871 12.5302 66.1904 12.7633 65.616 13.2294C65.0413 13.6957 64.6511 14.4273 64.4453 15.4243H71.3717C71.3717 14.5358 71.0682 13.8313 70.4612 13.3108C69.854 12.7904 69.0356 12.5302 68.0061 12.5302Z"
                fill="black"/>
            <path
                d="M78.5723 17.1641C78.5723 14.6061 79.3717 12.5005 80.9705 10.8475C82.5692 9.19468 84.6152 8.36816 87.1084 8.36792C89.5688 8.36792 91.5632 8.99934 93.0917 10.2622C94.6201 11.5251 95.4656 13.2458 95.6281 15.4243H90.4739C90.1053 13.7878 89.0485 12.9694 87.3035 12.9693C86.2194 12.9693 85.3549 13.3487 84.7101 14.1075C84.0651 14.8663 83.7426 15.8852 83.7427 17.1641C83.7427 18.4974 84.0516 19.5353 84.6695 20.2778C85.2873 21.0203 86.1436 21.3916 87.2384 21.3915C89.0701 21.3915 90.1486 20.5677 90.4739 18.9202H95.6281C95.4656 21.0232 94.6093 22.7277 93.0592 24.0337C91.509 25.3399 89.5687 25.993 87.2384 25.9929C84.6044 25.9929 82.5016 25.188 80.9299 23.5784C79.3581 21.9688 78.5722 19.8307 78.5723 17.1641Z"
                fill="black"/>
            <path
                d="M103.547 25.5538H98.4258V0.449707H103.547V14.9041L108.945 8.90452H115.368L109.108 15.5057L115.173 25.5538H109.238L105.515 19.3266L103.547 21.3915V25.5538Z"
                fill="black"/>
            <path
                d="M120.652 25.9929C120.25 25.9994 119.851 25.9234 119.479 25.7696C119.107 25.6158 118.77 25.3874 118.49 25.0986C118.207 24.8216 117.982 24.4909 117.828 24.1259C117.675 23.7608 117.596 23.3687 117.596 22.9727C117.596 22.5767 117.675 22.1846 117.828 21.8195C117.982 21.4545 118.207 21.1238 118.49 20.8468C118.771 20.5601 119.108 20.3337 119.48 20.1813C119.852 20.029 120.251 19.9539 120.652 19.9608C121.053 19.9579 121.45 20.0355 121.82 20.1891C122.19 20.3427 122.526 20.5691 122.807 20.8549C123.1 21.1265 123.333 21.4563 123.491 21.823C123.649 22.1898 123.729 22.5855 123.725 22.9849C123.727 23.3824 123.646 23.776 123.486 24.1401C123.327 24.5042 123.092 24.8307 122.799 25.0986C122.518 25.383 122.184 25.6086 121.816 25.7622C121.447 25.9157 121.052 25.9942 120.652 25.9929Z"
                fill="black"/>
            <path
                d="M127.709 33.5535V8.90462H132.538L132.798 10.937C133.264 10.1675 133.987 9.54695 134.969 9.07525C135.977 8.5977 137.081 8.35584 138.196 8.36799C140.548 8.36799 142.445 9.14033 143.887 10.685C145.328 12.2295 146.049 14.3242 146.049 16.9691C146.049 19.6574 145.353 21.8389 143.96 23.5134C142.567 25.1881 140.657 26.0254 138.229 26.0255C137.136 26.0339 136.052 25.8353 135.034 25.4402C134.042 25.0498 133.308 24.5349 132.831 23.8955V33.5535H127.709ZM133.993 14.2132C133.24 14.9773 132.863 15.9827 132.863 17.2293C132.863 18.4759 133.24 19.4812 133.993 20.2453C134.746 21.0095 135.725 21.3916 136.928 21.3916C138.142 21.3916 139.106 21.0095 139.822 20.2453C140.537 19.4812 140.895 18.4758 140.895 17.2293C140.895 15.9829 140.537 14.9776 139.822 14.2132C139.107 13.4489 138.142 13.0669 136.928 13.0669C135.725 13.0669 134.746 13.449 133.993 14.2132Z"
                fill="black"/>
            <path
                d="M160.452 8.90453V13.7335H158.826C155.694 13.7335 154.127 15.2673 154.127 18.3348V25.5538H149.006V8.93707H153.835L154.095 11.4084C154.44 10.5912 155.02 9.89508 155.761 9.40847C156.504 8.92079 157.412 8.67693 158.485 8.67688C159.147 8.67702 159.807 8.75341 160.452 8.90453Z"
                fill="black"/>
            <path
                d="M164.228 23.5378C162.504 21.9228 161.643 19.809 161.643 17.1967C161.643 14.5845 162.504 12.4654 164.228 10.8393C165.951 9.21346 168.157 8.40052 170.845 8.40051C173.512 8.40051 175.704 9.21345 177.422 10.8393C179.14 12.4654 179.999 14.5845 179.999 17.1967C179.999 19.809 179.143 21.9227 177.43 23.5378C175.717 25.1531 173.522 25.9606 170.845 25.9604C168.157 25.9604 165.951 25.1529 164.228 23.5378ZM166.813 17.1642C166.813 18.4218 167.184 19.4353 167.927 20.2047C168.669 20.9745 169.642 21.3592 170.845 21.3591C172.027 21.3591 172.986 20.9743 173.723 20.2047C174.46 19.4351 174.829 18.4216 174.829 17.1642C174.829 15.9178 174.46 14.9125 173.723 14.1481C172.986 13.384 172.027 13.0019 170.845 13.0019C169.642 13.0019 168.669 13.3839 167.927 14.1481C167.184 14.9123 166.813 15.9176 166.813 17.1642Z"
                fill="black"/>
        </svg>

    );
}

export default CheckLogoSVG;
import React, {useMemo} from 'react';
import styled from "styled-components";
import {Modal, ModalProps} from "antd";
import {useAppSelector} from "../redux/hooks";
import {SearchDataType} from "../utils/types";
import {uiScale} from "../config";

const ModalContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: calc(36px * ${uiScale});
    white-space: pre-line;

    h1 {
        font-size: calc(28px * ${uiScale});
        line-height: calc(28px * ${uiScale});
        font-weight: 600;
        font-family: "Suisse Intl", Geometria, Arial, sans-serif;
        color: ${({theme}) => theme.black};
    }

    a {
        color: blue;
        font-size: calc(22px * ${uiScale});
        line-height: calc(22px * ${uiScale});
        font-weight: 500;
    }
`;

const Group = styled.div`
    display: flex;
    flex-direction: column;
    gap: calc(12px * ${uiScale});

    .groupText {
        display: flex;
        gap: calc(10px * ${uiScale});
        align-items: center;

        .title {
            margin-bottom: 0;
        }
    }

    .svgWrap {
        display: flex;
        justify-content: center;
        align-items: center;
        width: calc(24px * ${uiScale});
        height: calc(24px * ${uiScale});
        background: ${({theme}) => theme.blue};
        border-radius: calc(3px * ${uiScale});
        flex: 0 0 auto;

        svg {
            height: calc(20px * ${uiScale});
        }
    }

    .title {
        display: inline-flex;
        gap: calc(10px * ${uiScale});
        color: ${({theme}) => theme.black};
        font-size: calc(22px * ${uiScale});
        font-weight: 500;
        line-height: calc(22px * ${uiScale});
        margin-bottom: calc(8px * ${uiScale});
    }

    .text {
        color: ${({theme}) => theme.gray2};
        font-size: calc(20px * ${uiScale});
        font-weight: 500;
    }
`;
const StyledSearchResultsModal = styled(Modal)`
    display: flex;
    width: auto !important;
    justify-content: center;
    top: calc(50px * ${uiScale});
    max-width: calc(1200px * ${uiScale});

    .ant-modal-content {
        padding: calc(60px * ${uiScale}) calc(30px * ${uiScale});
    }
`;

function SearchResultsModal({forceData, ...props}: ModalProps & { forceData?: SearchDataType }) {
    const searchData = useAppSelector((state) => state.page.searchData);

    const changedText = useMemo(() => {
        return {...(forceData ? forceData : searchData)}?.Raw?.Text?.replace(/(^[^:\n]+):/gm, (match) => {
            return `<span class="title">${match}</span>`;
        })
    }, [searchData, forceData]);

    return (
        <StyledSearchResultsModal footer={null} {...props}>
            <ModalContent>
                <h1>Результаты поиска</h1>
                {/*<>*/}
                {/*    <Group>*/}
                {/*<span className="title">*/}
                {/*    <span className="svgWrap">*/}
                {/*        <SmartPhoneSVG/>*/}
                {/*    </span>*/}
                {/*</span>*/}
                {/*        <span className="text">Номер: 79999999999</span>*/}
                {/*        <span className="text">Страна: Россия</span>*/}
                {/*        <span className="text">Регион: Челябинская область</span>*/}
                {/*        <span className="text">Оператор: Мегафон</span>*/}
                {/*    </Group>*/}
                {/*    <Group>*/}
                {/*    <span className="title">*/}
                {/*        <span className="svgWrap">*/}
                {/*            <FileSVG/>*/}
                {/*        </span>*/}
                {/*        Возможные имена:</span>*/}
                {/*        <span className="text">Иван Иванов, Иван Иванько, Иван Иванцев, Иван Иваново</span>*/}
                {/*    </Group>*/}
                {/*    <Group>*/}
                {/*    <span className="title">*/}
                {/*        <span className="svgWrap">*/}
                {/*            <UserSVG/>*/}
                {/*        </span>*/}
                {/*        ФИО:*/}
                {/*    </span>*/}
                {/*        <span className="text">Иванов Иван Иванович</span>*/}
                {/*        <span className="text">Иванов Иван Иванович</span>*/}
                {/*        <span className="text">Иванов Иван Иванович</span>*/}
                {/*        <span className="text">Иванов Иван Иванович</span>*/}
                {/*    </Group>*/}

                {/*    <Group>*/}
                {/*    <span className="title">*/}
                {/*         <span className="svgWrap">*/}
                {/*            <LocationSVG/>*/}
                {/*        </span>*/}
                {/*        Возможные адреса:*/}
                {/*    </span>*/}
                {/*        <span className="text">Челябинская Область, Челябинск</span>*/}
                {/*        <span className="text">Краснодарский Край, Краснодар</span>*/}
                {/*        <span className="text">Свердловская Область, Екатеринбург</span>*/}
                {/*        <span className="text">456550, Челябинская Обл. Коркинский р-н, г. Коркино, ул. Полевая, д 63</span>*/}
                {/*        <span className="text">456550, Коркино, Полевая, 63 кв</span>*/}
                {/*        <span className="text">Челябинская Область, Челябинск Г. Ленина Пр-кт д.89, Офис 510</span>*/}
                {/*    </Group>*/}

                {/*    <Group>*/}
                {/*    <span className={"groupText"}><span className="title">*/}
                {/*        <span className="svgWrap">*/}
                {/*            <WorldSVG/>*/}
                {/*        </span>*/}
                {/*        Возможный IP:</span> <span*/}
                {/*        className="text">176.59.193.72</span></span>*/}
                {/*        <span className={"groupText"}><span className="title">*/}
                {/*        <span className="svgWrap">*/}
                {/*            <VKSVG/>*/}
                {/*        </span>*/}
                {/*        Вконтакте:</span> <span className="text">Иван Иванов</span></span>*/}
                {/*        <span className={"groupText"}><span className="title">*/}
                {/*        <span className="svgWrap">*/}
                {/*            <BirthdaySVG/>*/}
                {/*        </span>*/}
                {/*        Дата рождения:</span> <span className="text">14.03.2000 (24 года), 03.01.1989 (35 лет), 05.04.1999 (24 года).</span></span>*/}
                {/*        <span className={"groupText"}><span className="title">*/}
                {/*        <span className="svgWrap">*/}
                {/*            <MailSVG/>*/}
                {/*        </span>*/}
                {/*        E-mail:</span> <span*/}
                {/*            className="text">ivanov.ivan@yandex.ru</span></span>*/}
                {/*        <span className={"groupText"}><span className="title">*/}
                {/*        <span className="svgWrap">*/}
                {/*            <OKSVG/>*/}
                {/*        </span>*/}
                {/*        Одноклассники:</span> <span className="text">Иванов Иван</span></span>*/}
                {/*        <span className={"groupText"}><span className="title">*/}
                {/*        <span className="svgWrap">*/}
                {/*            <Telegram2SVG/>*/}
                {/*        </span>*/}
                {/*        Telegram:</span> <span*/}
                {/*            className="text">@ivanov.ivan | ID 803902195</span></span>*/}
                {/*        <span className={"groupText"}><span className="title">*/}
                {/*        <span className="svgWrap">*/}
                {/*            <UsersSVG/>*/}
                {/*        </span>*/}
                {/*        Группы Telegram:</span> <span className="text">3</span></span>*/}
                {/*        <span className={"groupText"}>*/}
                {/*        <span className="title">*/}
                {/*        <span className="svgWrap">*/}
                {/*            <WhatsApp2SVG/>*/}
                {/*        </span>*/}
                {/*        Whatsapp:</span> <span className="text">найдено</span></span>*/}
                {/*        <span className={"groupText"}><span className="title">*/}
                {/*        <span className="svgWrap">*/}
                {/*            <ViberSVG/>*/}
                {/*        </span>*/}
                {/*        Viber:</span> <span className="text">найдено</span></span>*/}
                {/*        <span className={"groupText"}><span className="title">*/}
                {/*        <span className="svgWrap">*/}
                {/*            <CardSVG/>*/}
                {/*        </span>*/}
                {/*        Мобильный банк:</span> <span className="text">Сбербанк</span></span>*/}
                {/*        <span className={"groupText"}><span className="title">*/}
                {/*        <span className="svgWrap">*/}
                {/*            <AdSVG/>*/}
                {/*        </span>*/}
                {/*        Объявлений:</span> <span className="text">7 шт</span></span>*/}
                {/*    </Group>*/}

                {/*    <Group>*/}
                {/*    <span className={"groupText"}><span className="title">*/}
                {/*        <span className="svgWrap">*/}
                {/*            <EyeSVG/>*/}
                {/*        </span>*/}
                {/*        Интересовались:</span> <span className="text">4 человека</span></span>*/}
                {/*        <span className={"groupText"}><span className="title">*/}
                {/*        <span className="svgWrap">*/}
                {/*            <MedalSVG/>*/}
                {/*        </span>*/}
                {/*        Репутация:</span> <span className="text">👍 (0) 👎 (0)</span></span>*/}
                {/*    </Group>*/}

                {/*    <Group>*/}
                {/*    <span className="title">*/}
                {/*        <span className="svgWrap">*/}
                {/*            <InfoSVG/>*/}
                {/*        </span>*/}
                {/*        Если информация не найдена, закажите (Что тут будет?)</span>*/}
                {/*    </Group>*/}
                {/*</>*/}

                {/*<Group>*/}
                {/*    <a href="/">https://vk.com/id222550054</a>*/}
                {/*    <a href="/">https://ok.ru/profile/576885267350</a>*/}
                {/*</Group>*/}
                <Group>
                    <span className={"text"} dangerouslySetInnerHTML={{__html: changedText || ""}}/>
                </Group>
                <Group>
                    {{...(forceData ? forceData : searchData)}?.Raw?.Links?.map((link, index) => <a key={index} target={"_blank"} rel="noopener noreferrer" href={link}>{link}</a>)}
                </Group>
            </ModalContent>
        </StyledSearchResultsModal>
    );
}

export default SearchResultsModal;
import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {StyledInput} from "../components/styled/StyledInput";
import {Space} from "antd";
import PhoneSVG from "../images/PhoneSVG";
import MailSVG from "../images/MailSVG";
import Telegram2SVG from "../images/Telegram2SVG";
import DocumentSVG from "../images/DocumentSVG";
import EmblemSVG from "../images/EmblemSVG";
import SNILSSVG from "../images/SNILSSVG";
import CarSVG from "../images/CarSVG";
import {StyledButton} from "../components/styled/StyledButton";
import {Link} from "react-router-dom";
import SearchResultsModal from "../components/SearchResultsModal";
import {useAppDispatch, useAppSelector} from "../redux/hooks";
import {changeSearchFieldsAction, searchDataAction} from "../redux/pageSlice";
import * as _ from "lodash/fp";
import VKSVG from "../images/VKSVG";
import {uiScale} from "../config";

const Blocks = styled.div`
    display: flex;
    flex-direction: column;
    gap: calc(20px * ${uiScale});
`;
const Block = styled.div`
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 calc(8px * ${uiScale}) 0 #0000001A;
    padding: calc(30px * ${uiScale}) calc(20px * ${uiScale});
    border-radius: calc(16px * ${uiScale});

    .ant-space-item {
        width: 100%;
    }

    .field {
        display: flex;
        width: 100%;
        gap: calc(13px * ${uiScale});

        .ant-input {
            width: 100%;
        }
    }

    .btn {
        padding: calc(18px * ${uiScale});
        border-radius: calc(14px * ${uiScale});
        background: ${({theme}) => theme.blue};
        display: flex;
        align-items: center;
        justify-content: center;
        width: calc(54px * ${uiScale});
        height: calc(54px * ${uiScale});
    }

    h2 {
        font-family: 'Suisse Intl', Arial, sans-serif;
        margin-bottom: calc(40px * ${uiScale});
        font-size: calc(28px * ${uiScale});
        line-height: calc(28px * ${uiScale});
        font-weight: 500;
        cursor: pointer;

        &.hidden {
            color: ${({theme}) => theme.gray2};
        }
    }

    .ant-space {
        .ant-space {
            width: 100%;
        }

        &:not(:last-child) {
            margin-bottom: calc(20px * ${uiScale});
        }
    }
`;
const Field = styled.div`
    display: flex;
    flex-direction: column;
    max-width: calc(290px * ${uiScale});
    width: 100%;
    gap: calc(10px * ${uiScale});

    label {
        font-size: calc(18px * ${uiScale});
        color: ${({theme}) => theme.gray3};
    }
`;
const StyledSearchPage = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    flex-direction: column;
    color: ${({theme}) => theme.black};


    a {
        color: ${({theme}) => theme.blue};
    }

    .footerText {
        padding-bottom: calc(60px * ${uiScale});
        margin-top: calc(20px * ${uiScale});
        font-weight: 500;
        color: ${({theme}) => theme.gray2};
    }

    ${StyledButton} {
        margin-top: calc(20px * ${uiScale});
        width: 100%;
    }

    .fioSearch {
        .ant-space {
            max-height: calc(300px * ${uiScale});

            .ant-space-item {
                &:not(:last-child) {
                    margin-bottom: calc(20px * ${uiScale});
                }
            }
        }
    }

    .ant-space {
        max-height: calc(60px * ${uiScale});
        transition: all .5s;
        overflow: hidden;

        &.hidden {
            max-height: 0;
        }

        &.inputInactive {
            .btn {
                background: ${({theme}) => theme.gray2};
            }
        }
    }

    h1 {
        font-family: 'Suisse Intl', Arial, sans-serif;
        font-size: calc(46px * ${uiScale});
        font-weight: 600;
        line-height: calc(41px * ${uiScale});
        text-align: center;
        margin-bottom: calc(40px * ${uiScale});
    }
`;

function SearchPage() {

    const [resultsOpen, setResultsOpen] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const searchFields = useAppSelector((state) => state.page.searchFields);

    const [currentTab, setCurrentTab] = useState<"VK" | "Phone" | "other">("Phone")
    const [currentInput, setCurrentInput] = useState<"VK" | "Telegram" | "Email" | "Phone" | "inn" | "passport" | "snils" | "auto" | "">("")

    useEffect(() => {
        dispatch(changeSearchFieldsAction({}))
    }, [currentTab]);

    return (
        <StyledSearchPage>
            <SearchResultsModal onCancel={() => setResultsOpen(false)} open={resultsOpen}/>

            <h1>Поиск</h1>
            <Blocks>
                {/*<Block>*/}
                {/*    <h2>Поиск по ФИО и дате рождения</h2>*/}
                {/*    <Space size={20}>*/}
                {/*        <Field>*/}
                {/*            <label>Фамилия</label>*/}
                {/*            <StyledInput/>*/}
                {/*        </Field>*/}
                {/*        <Field>*/}
                {/*            <label>Имя</label>*/}
                {/*            <StyledInput/>*/}
                {/*        </Field>*/}
                {/*        <Field>*/}
                {/*            <label>Отчество</label>*/}
                {/*            <StyledInput/>*/}
                {/*        </Field>*/}
                {/*    </Space>*/}
                {/*    <Space size={20}>*/}
                {/*        <Field>*/}
                {/*            <label>День</label>*/}
                {/*            <StyledInput/>*/}
                {/*        </Field>*/}
                {/*        <Field>*/}
                {/*            <label>Месяц</label>*/}
                {/*            <StyledInput/>*/}
                {/*        </Field>*/}
                {/*        <Field>*/}
                {/*            <label>Год</label>*/}
                {/*            <StyledInput/>*/}
                {/*        </Field>*/}
                {/*    </Space>*/}
                {/*</Block>*/}
                <Block>
                    <h2 className={currentTab === "Phone" ? "" : "hidden"} onClick={() => setCurrentTab("Phone")}>
                        Поиск по номеру телефона или E-mail адресу
                    </h2>
                    <Space size={20}
                           className={currentTab === "Phone" ? "" : "hidden"}>
                        <Space className={currentInput === "Phone" ? "" : "inputInactive"}>
                            <span className="field">
                            <span className="btn">
                            <PhoneSVG/>
                        </span>
                        <StyledInput onFocus={() => setCurrentInput("Phone")} value={searchFields.Phone}
                                     onChange={({target: {value}}) => {
                                         dispatch(changeSearchFieldsAction(_.set(["Phone"], value, searchFields)))
                                     }} placeholder={"+7(999)-000-00-00"}/>
                        </span>
                        </Space>
                        <Space className={currentInput === "Email" ? "" : "inputInactive"}>
                            <span className="field">
                            <span className="btn">
                            <MailSVG/>
                        </span>
                        <StyledInput onFocus={() => setCurrentInput("Email")} value={searchFields.Email}
                                     onChange={({target: {value}}) => {
                                         dispatch(changeSearchFieldsAction(_.set(["Email"], value, searchFields)))
                                     }} placeholder={"test@mail.ru"}/>
                        </span>
                        </Space>
                    </Space>
                </Block>
                <Block>
                    <h2 className={currentTab === "VK" ? "" : "hidden"} onClick={() => setCurrentTab("VK")}>
                        Поиск по ссылке Вконтакте или Никнейм в
                        Telegram
                    </h2>
                    <Space size={20} className={currentTab === "VK" ? "" : "hidden"}>
                       <Space className={currentInput === "VK" ? "" : "inputInactive"}>
                            <span className="field">
                            <span className="btn">
                            <VKSVG/>
                        </span>
                        <StyledInput onFocus={() => setCurrentInput("VK")} value={searchFields.VK}
                                     onChange={({target: {value}}) => {
                                         dispatch(changeSearchFieldsAction(_.set(["VK"], value, searchFields)))
                                     }} placeholder={"https://vk.com/"}/>
                        </span>
                       </Space>
                        <Space className={currentInput === "Telegram" ? "" : "inputInactive"}>
                            <span className="field">
                            <span className="btn">
                            <Telegram2SVG/>
                        </span>
                        <StyledInput onFocus={() => setCurrentInput("Telegram")} value={searchFields.Telegram}
                                     onChange={({target: {value}}) => {
                                         dispatch(changeSearchFieldsAction(_.set(["Telegram"], value, searchFields)))
                                     }} placeholder={"@name"}/>
                        </span>
                        </Space>
                    </Space>
                </Block>
                <Block className={"fioSearch"}>
                    <h2 className={currentTab === "other" ? "" : "hidden"} onClick={() => setCurrentTab("other")}>
                        Другое
                    </h2>
                    <Space direction={"vertical"} size={0} className={currentTab === "other" ? "" : "hidden"}>
                        <Space className={currentInput === "inn" ? "" : "inputInactive"}>
                            <span className="field">
                        <span className="btn">
                            <DocumentSVG/>
                        </span>
                        <StyledInput onFocus={() => setCurrentInput("inn")} value={searchFields.inn}
                                     onChange={({target: {value}}) => {
                                         dispatch(changeSearchFieldsAction(_.set(["inn"], value, searchFields)))
                                     }} placeholder={"ИНН физ. лица"}/>
                    </span>
                        </Space>
                        <Space className={currentInput === "passport" ? "" : "inputInactive"}>
                            <span className="field">
                        <span className="btn">
                            <EmblemSVG/>
                        </span>
                        <StyledInput onFocus={() => setCurrentInput("passport")} value={searchFields.passport}
                                     onChange={({target: {value}}) => {
                                         dispatch(changeSearchFieldsAction(_.set(["passport"], value, searchFields)))
                                     }} placeholder={"Номер паспорта"}/>
                    </span>
                        </Space>
                        <Space className={currentInput === "snils" ? "" : "inputInactive"}>
                            <span className="field">
                        <span className="btn">
                            <SNILSSVG/>
                        </span>
                        <StyledInput onFocus={() => setCurrentInput("snils")} value={searchFields.snils}
                                     onChange={({target: {value}}) => {
                                         dispatch(changeSearchFieldsAction(_.set(["snils"], value, searchFields)))
                                     }} placeholder={"Номер СНИЛС"}/>
                    </span>
                        </Space>
                        <Space className={currentInput === "auto" ? "" : "inputInactive"}>
                            <span className="field">
                        <span className="btn">
                            <CarSVG/>
                        </span>
                        <StyledInput onFocus={() => setCurrentInput("auto")} value={searchFields.auto}
                                     onChange={({target: {value}}) => {
                                         dispatch(changeSearchFieldsAction(_.set(["auto"], value, searchFields)))
                                     }} placeholder={"Номер Автомобиля или В/У"}/>
                    </span>
                        </Space>
                    </Space>
                </Block>

                <StyledButton
                    disabled={!searchFields[currentInput]}
                    onClick={() => {
                        dispatch(searchDataAction({value: currentInput === "VK" ? searchFields[currentInput].match(/(?:https?:\/\/)?(?:vk\.com\/)?([^\/]+)/)[1] : searchFields[currentInput], type: currentInput.toLowerCase()})).unwrap().then(() => {
                            window.scrollTo(0, 0)
                            setResultsOpen(true)
                        }).catch(() => {

                        })
                    }}>Начать поиск</StyledButton>
                <span className={"footerText"}>Выполняя запрос Вы автоматически соглашаетесь с <Link to={"/"}>правилами проекта</Link> и даете согласие на <Link
                    to={"/"}>обработку персональных данных.</Link></span>
            </Blocks>
        </StyledSearchPage>
    );
}

export default SearchPage;
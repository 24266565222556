import React, {useState} from 'react';
import styled from "styled-components";
import {Button, Form, Space} from "antd";
import {uiScale} from "../config";
import LogoSVG from "../images/LogoSVG";
import blueBackground from "../images/blueBackground.png";
import {Link} from "react-router-dom";
import {StyledInput} from "../components/styled/StyledInput";
import {StyledButton} from "../components/styled/StyledButton";
import {StyledSelect} from "../components/styled/StyledSelect";
import {StyledCheckbox} from "../components/styled/StyledCheckbox";
import {useAppDispatch} from "../redux/hooks";
import {authAction, regAction} from "../redux/pageSlice";
import CheckLogoSVG from "../images/CheckLogoSVG";
import {StyledCheckInput} from "../components/styled/StyledCheckInput";

const Top = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: calc(36px * ${uiScale});
    a{
        color: ${({theme}) => theme.gray};
        font-weight: 500;
        font-size: calc(18px * ${uiScale});
    }

    h2 {
        font-size: calc(42px * ${uiScale});
        font-family: "Suisse Intl", Geometria, Arial, sans-serif;
        font-weight: 600;
        margin: 0;
    }
`;

const Actions = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: calc(12px * ${uiScale});

    a {
        font-size: calc(18px * ${uiScale});
        color: ${({theme}) => theme.gray};
    }
`;

const FormWrap = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: calc(18px * ${uiScale});
    padding: calc(52px * ${uiScale});
    border: calc(1px * ${uiScale}) solid ${({theme}) => theme.blue};
    min-width: calc(750px * ${uiScale});
    margin-bottom: calc(100px * ${uiScale});
    max-width: calc(758px * ${uiScale});

    .ant-form {
        width: 100%;

        .ant-btn {
            width: 100%;
        }

        .ant-form-item {
            &:last-child {
                margin-bottom: 0;
            }
        }

    }
`;

const Footer = styled.div`
    display: flex;
    margin-top: auto;
    color: ${({theme}) => theme.gray};
    font-size: calc(16px * ${uiScale});
    padding-bottom: calc(20px * ${uiScale});
`;

const SuccessfulBlock = styled.div`
    > span {
        display: flex;
        padding: calc(65px * ${uiScale}) calc(115px * ${uiScale});
        font-size: calc(28px * ${uiScale});
        font-weight: 500;
        color: ${({theme}) => theme.white0};
        background: url(${blueBackground}) no-repeat;
        background-size: contain;
        border-radius: calc(18px * ${uiScale});
        text-align: center;
    }

    display: flex;
    flex-direction: column;
    gap: calc(36px * ${uiScale});
`;

const StyledAuthPage = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    padding-top: calc(250px * ${uiScale});

    ${StyledCheckbox} {
        a {
            color: ${({theme}) => theme.blue};
        }
    }

    h1 {
        font-size: calc(32px * ${uiScale});
        text-align: center;
        color: ${({theme}) => theme.black};
        margin-bottom: calc(40px * ${uiScale});
    }
`;

function AuthPage() {

    const [formType, setFormType] = useState<"auth" | "reg" | "successful">("auth");
    const dispatch = useAppDispatch();

    const [regChecked, setRegChecked] = useState<boolean>(false);

    return (
        <StyledAuthPage>
            <FormWrap>
                <Top>
                    <Space direction={"vertical"}>
                        <h2>{formType === "auth" ? "Авторизация" : formType === "successful" ? "Успешная регистрация" : "Создать аккаунт"}</h2>
                        {formType === "reg" && <Link to={"#"} onClick={() => setFormType("auth")}>
                            Авторизоваться
                        </Link>}
                    </Space>
                    <CheckLogoSVG/>
                </Top>
                {formType === "auth" ? <Form onFinish={({login, password}) => {
                    dispatch(authAction({login, password}))
                }}>
                    <Form.Item name={"login"}>
                        <StyledCheckInput placeholder={"Логин"}/>
                    </Form.Item>
                    <Form.Item name={"password"}>
                        <StyledCheckInput.Password placeholder={"Пароль"}/>
                    </Form.Item>
                    <Form.Item>
                        <Actions>
                            <Link to={"#"}>
                                Не помню пароль
                            </Link>
                            <Link to={"#"} onClick={() => setFormType("reg")}>
                                Создать аккаунт
                            </Link>
                        </Actions>
                    </Form.Item>
                    <Form.Item>
                        <StyledButton htmlType={"submit"}>Войти</StyledButton>
                    </Form.Item>
                </Form> : formType === "successful" ? <SuccessfulBlock>
                    <span>
                        Спасибо за регистрацию! <br/>
                        На вашу почту отправили письмо с паролем для входа
                    </span>
                    <StyledButton onClick={() => {
                        setFormType("auth");
                    }}>Войти в систему</StyledButton>
                </SuccessfulBlock> : <Form onFinish={(fields) => {
                    dispatch(regAction(fields)).unwrap().then(() => {
                        setFormType("successful");
                    })
                }}>
                    <Form.Item name={"Name"}>
                        <StyledCheckInput placeholder={"Имя"}/>
                    </Form.Item>
                    <Form.Item rules={[{
                        required: true,
                        message: "Пожалуйста, введите E-mail"
                    }]} name={"Login"}>
                        <StyledCheckInput placeholder={"E-mail"}/>
                    </Form.Item>
                    <Form.Item name={"Phone"}>
                        <StyledCheckInput placeholder={"Телефон"}/>
                    </Form.Item>
                    {/*<Form.Item>*/}
                    {/*    <Actions>*/}
                    {/*        <Link to={"*"}>*/}
                    {/*            Войти*/}
                    {/*        </Link>*/}
                    {/*    </Actions>*/}
                    {/*</Form.Item>*/}
                    <Form.Item>
                        <StyledCheckbox checked={regChecked} onChange={({target: {checked}}) => setRegChecked(checked)}>Регистрируясь,
                            я соглашаюсь с условиями <Link to={"#"}>«Оферты»</Link> и даю
                            согласие на обработку
                            своих персональных данных в соответствии с <Link to={"#"}>«Политикой
                                конфиденциальности»</Link></StyledCheckbox>
                    </Form.Item>
                    <Form.Item>
                        <StyledButton disabled={!regChecked} htmlType={"submit"}>Зарегистрироваться</StyledButton>
                    </Form.Item>
                </Form>}
            </FormWrap>
        </StyledAuthPage>
    );
}

export default AuthPage;
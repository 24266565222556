import React, {useEffect} from 'react';
import styled, {ThemeProvider} from "styled-components";
import {Route, Routes, useLocation, useNavigate} from "react-router-dom";
import MainLayout from "./layouts/MainLayout";
import AuthPage from "./pages/AuthPage";
import {colors} from "./colors";
import CheckLayout from "./layouts/CheckLayout";
import SearchPage from "./pages/SearchPage";
import History from "./utils/history";
import {useAppDispatch, useAppSelector} from "./redux/hooks";
import {getUserAction} from "./redux/pageSlice";
import HistoryPage from "./pages/HistoryPage";
import TariffsPage from "./pages/TariffsPage";
import ProfilePage from "./pages/ProfilePage";

const StyledApp = styled.div`
    display: flex;
    width: 100%;
`;

function App() {
    const user = useAppSelector((state) => state.page.user);
    const loading = useAppSelector((state) => state.page.loading);


    const theme_colors = colors;

    const location = useLocation();

    History.navigate = useNavigate();
    History.location = location;
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(getUserAction())
    }, []);

    return (
        <StyledApp>

            <ThemeProvider theme={theme_colors}>
                <Routes>
                    {loading ? <Route path={"*"} element={
                        <>
                            Loading
                        </>
                    }></Route> : user?.Login ? <>
                        <Route path={"/"} element={<CheckLayout>
                            <SearchPage/>
                        </CheckLayout>}/>
                        <Route path={"/history"} element={<CheckLayout>
                            <HistoryPage/>
                        </CheckLayout>}/>
                        <Route path={"/tariffs"} element={<CheckLayout>
                            <TariffsPage/>
                        </CheckLayout>}/>
                        <Route path={"/profile"} element={<CheckLayout>
                            <ProfilePage/>
                        </CheckLayout>}/>
                    </> : <>
                        <Route path={"*"} element={<MainLayout>
                            <AuthPage/>
                        </MainLayout>}/>
                    </>}

                </Routes>
            </ThemeProvider>
        </StyledApp>
    );
}

export default App;

import React, {useEffect} from 'react';
import styled from "styled-components";
import {message, Space} from "antd";
import {StyledButton} from "../components/styled/StyledButton";
import {uiScale} from "../config";
import {useAppDispatch, useAppSelector} from "../redux/hooks";
import {getPlansAction} from "../redux/pageSlice";
import {declension} from "../utils/Declension";

const Tariffs = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    flex-wrap: wrap;
    gap: calc(20px * ${uiScale});
    justify-content: center;
`;
const Tariff = styled.div`
    display: flex;
    flex-direction: column;
    background: ${({theme}) => theme.lightBlue};
    padding: calc(30px * ${uiScale}) calc(20px * ${uiScale});
    border-radius: calc(16px * ${uiScale});

    h3 {
        font-family: "Suisse Intl", Arial, sans-serif;
        font-size: calc(28px * ${uiScale});
        line-height: calc(28px * ${uiScale});

        span {
            color: ${({theme}) => theme.blue};
        }
    }

    ${StyledButton} {
        padding: calc(12px * ${uiScale}) calc(18px * ${uiScale});
        font-size: calc(16px * ${uiScale});
    }

    span {
        &.price, &.bonus {
            color: ${({theme}) => theme.blue};
            font-size: calc(18px * ${uiScale});
            line-height: calc(18px * ${uiScale});
            font-weight: 500;
            margin-bottom: calc(10px * ${uiScale});
        }

        &.bonus {
            color: ${({theme}) => theme.gray2};
            margin-bottom: calc(20px * ${uiScale});
        }
    }
`;
const StyledTariffsPage = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    h1 {
        color: ${({theme}) => theme.black};
        font-size: calc(46px * ${uiScale});
        margin-bottom: calc(40px * ${uiScale});
        font-family: "Suisse Intl", Arial, sans-serif;
    }

    h2 {
        margin-bottom: calc(40px * ${uiScale});
        font-size: calc(18px * ${uiScale});
        color: ${({theme}) => theme.gray2};
        font-family: "Suisse Intl", Arial, sans-serif;
    }
`;

function TariffsPage() {
    const dispatch = useAppDispatch();
    const plans = useAppSelector((state) => state.page.plans);

    const messageMockup = () => {
        message.warning(<span>Свяжитесь с нами по электронной почте <a
            href="mailto:vaizmanai@gmail.com">vaizmanai@gmail.com</a></span>)
    }

    useEffect(() => {
        dispatch(getPlansAction())
    }, []);

    return (
        <StyledTariffsPage>
            <h1>Тарифы</h1>
            <h2>7,5 ₽ - 1 запрос. Больше запросов - дешевле цена!</h2>
            <Tariffs>
                {plans?.map(plan => <Tariff key={plan.Id}>
                    <h3><span>{plan.Value}</span> {declension((plan.Value || 0), ['запрос', 'запроса', 'запросов'])}
                    </h3>
                    <span className="price">{plan.Price} ₽</span>
                    <span className="bonus">{plan.Description}</span>
                    <Space>
                        <StyledButton onClick={messageMockup}>Выставить счёт</StyledButton>
                        <StyledButton onClick={messageMockup}>оплата по карте</StyledButton>
                    </Space>
                </Tariff>)}
                {/*<Tariff>*/}
                {/*    <h3><span>50</span> запросов</h3>*/}
                {/*    <span className="price">375 ₽</span>*/}
                {/*    <span className="bonus">Бонус +5 запросов</span>*/}
                {/*    <Space>*/}
                {/*        <StyledButton onClick={messageMockup}>Выставить счёт</StyledButton>*/}
                {/*        <StyledButton onClick={messageMockup}>оплата по карте</StyledButton>*/}
                {/*    </Space>*/}
                {/*</Tariff>*/}
                {/*<Tariff>*/}
                {/*    <h3><span>50</span> запросов</h3>*/}
                {/*    <span className="price">375 ₽</span>*/}
                {/*    <span className="bonus">Бонус +5 запросов</span>*/}
                {/*    <Space>*/}
                {/*        <StyledButton onClick={messageMockup}>Выставить счёт</StyledButton>*/}
                {/*        <StyledButton onClick={messageMockup}>оплата по карте</StyledButton>*/}
                {/*    </Space>*/}
                {/*</Tariff>*/}
                {/*<Tariff>*/}
                {/*    <h3><span>50</span> запросов</h3>*/}
                {/*    <span className="price">375 ₽</span>*/}
                {/*    <span className="bonus">Бонус +5 запросов</span>*/}
                {/*    <Space>*/}
                {/*        <StyledButton onClick={messageMockup}>Выставить счёт</StyledButton>*/}
                {/*        <StyledButton onClick={messageMockup}>оплата по карте</StyledButton>*/}
                {/*    </Space>*/}
                {/*</Tariff>*/}
                {/*<Tariff>*/}
                {/*    <h3><span>50</span> запросов</h3>*/}
                {/*    <span className="price">375 ₽</span>*/}
                {/*    <span className="bonus">Бонус +5 запросов</span>*/}
                {/*    <Space>*/}
                {/*        <StyledButton onClick={messageMockup}>Выставить счёт</StyledButton>*/}
                {/*        <StyledButton onClick={messageMockup}>оплата по карте</StyledButton>*/}
                {/*    </Space>*/}
                {/*</Tariff>*/}
                {/*<Tariff>*/}
                {/*    <h3><span>50</span> запросов</h3>*/}
                {/*    <span className="price">375 ₽</span>*/}
                {/*    <span className="bonus">Бонус +5 запросов</span>*/}
                {/*    <Space>*/}
                {/*        <StyledButton onClick={messageMockup}>Выставить счёт</StyledButton>*/}
                {/*        <StyledButton onClick={messageMockup}>оплата по карте</StyledButton>*/}
                {/*    </Space>*/}
                {/*</Tariff>*/}
            </Tariffs>
        </StyledTariffsPage>
    );
}

export default TariffsPage;
import {logger} from "redux-logger";
import {configureStore} from "@reduxjs/toolkit";
import pageReducer from "./pageSlice"


export const store = configureStore({
    reducer: {
        page: pageReducer
    },
    devTools: process.env.NODE_ENV !== 'production',
    middleware: (getDefaultMiddleware) => {
        const defaultMiddleware = getDefaultMiddleware({
            serializableCheck: false
        })

        return process.env.NODE_ENV !== 'production' ? defaultMiddleware.concat(logger) : defaultMiddleware
    }
});

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
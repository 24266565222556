import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Space, Table, TableProps} from "antd";
import {SearchDataType} from "../utils/types";
import moment from "moment";
import EyeSVG from "../images/EyeSVG";
import DownloadSVG from "../images/DownloadSVG";
import TrashSVG from "../images/TrashSVG";
import {useAppDispatch, useAppSelector} from "../redux/hooks";
import {getHistoryAction} from "../redux/pageSlice";
import SearchResultsModal from "../components/SearchResultsModal";
import {uiScale} from "../config";

const StyledHistoryPage = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    h1 {
        font-size: calc(46px * ${uiScale});
        font-weight: 600;
        font-family: "Suisse Intl", Arial, sans-serif;
        margin-bottom: calc(40px * ${uiScale});
    }

    .ant-table-wrapper {
        max-width: calc(960px * ${uiScale});
        width: 100%;
        padding-bottom: calc(100px * ${uiScale});
        font-weight: 500;

        .ant-table-thead {
            font-size: calc(16px * ${uiScale});

            .ant-table-cell {
                background: ${({theme}) => theme.blue};
                color: ${({theme}) => theme.white0};

                &:first-child {
                    border-start-start-radius: calc(16px * ${uiScale}) !important;
                }

                &:last-child {
                    border-start-end-radius: calc(16px * ${uiScale}) !important;
                }

                &:before {
                    display: none;
                }
            }
        }

        .ant-table-tbody {
            font-size: calc(16px * ${uiScale});

            .ant-table-row {
                .ant-table-cell {
                    color: ${({theme}) => theme.black};
                    border-right: calc(2px * ${uiScale}) solid ${({theme}) => theme.gray4};
                    border-bottom: calc(2px * ${uiScale}) solid ${({theme}) => theme.gray4};

                    &:first-child {
                        border-left: calc(2px * ${uiScale}) solid ${({theme}) => theme.gray4};
                    }
                }

                &:last-child {
                    .ant-table-cell {
                        &:first-child {
                            border-bottom-left-radius: calc(16px * ${uiScale});
                        }

                        &:last-child {
                            border-bottom-right-radius: calc(16px * ${uiScale});
                        }
                    }
                }
            }
        }
    }

    .actions {
        svg {
            cursor: pointer;
            height: calc(18px * ${uiScale});
            width: calc(22px * ${uiScale});

            &.disabled {
                cursor: not-allowed;

                path, g rect {
                    fill: ${({theme}) => theme.gray4};

                }

                &:hover {
                    path, g rect {
                        fill: ${({theme}) => theme.gray5};
                    }
                }
            }

            path, g rect {
                fill: ${({theme}) => theme.gray2};
            }

            &:hover {
                path, g rect {
                    fill: ${({theme}) => theme.blue};
                }
            }
        }
    }

`;

function HistoryPage() {
    const columns: TableProps<SearchDataType>['columns'] = [
        {
            title: "Запрос",
            dataIndex: "Source"
        },
        {
            title: "Дата",
            dataIndex: "Timestamp",
            render: (value, record, index) => moment(value).format("DD.MM.YYYY в HH:mm")
        },
        {
            title: "Действие",
            render: (value, record, index) => <Space className={"actions"}>
                <EyeSVG onClick={() => {
                    setDataToShow(record)
                    setResultsOpen(true)
                }}/>
                <DownloadSVG className={"disabled"}/>
                <TrashSVG className={"disabled"}/>
            </Space>
        }
    ]
    const dispatch = useAppDispatch();
    const history = useAppSelector((state) => state.page.history);
    const [resultsOpen, setResultsOpen] = useState<boolean>(false);

    const [dataToShow, setDataToShow] = useState<SearchDataType | undefined>(undefined)

    useEffect(() => {
        dispatch(getHistoryAction())
    }, []);

    return (
        <StyledHistoryPage>
            <SearchResultsModal forceData={dataToShow} onCancel={() => {
                setResultsOpen(false)
                setDataToShow(undefined)
            }} open={resultsOpen}/>

            <h1>История</h1>
            <Table
                pagination={false}
                columns={columns}
                dataSource={history}
            >
            </Table>
        </StyledHistoryPage>
    );
}

export default HistoryPage;